
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons-vue';
import Editor from './compontent/editor.vue';

export default {
    name: "BasicShipper",
    components: {
        DeleteOutlined,
        PlusOutlined,
        Editor
    },
    data() {
        return {
            url: '/user/shipper/list',
            columns: [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('address.fields.name'),
                    dataIndex: 'name',
                    width: 200,
                    sorter: true,
                    slots: {
                        customRender: 'name'
                    }
                },
                {
                    title: this.$t('address.fields.address'),
                    dataIndex: 'address1',
                    sorter: true,
                    className: "max-address",
                    slots: {
                        customRender: 'address'
                    }
                },
                {
                    title: this.$t('address.fields.city'),
                    dataIndex: 'city',
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t('address.fields.state_code'),
                    dataIndex: 'state_code',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.postal_code'),
                    dataIndex: 'postal_code',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.country_code'),
                    dataIndex: 'country_code',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.phone_number'),
                    dataIndex: 'phone_number',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('address.fields.status'),
                    dataIndex: 'confirm',
                    width: 100,
                    sorter: true,
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: this.$t('share.created'),
                    dataIndex: 'created',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('share.operation'),
                    key: 'action',
                    width: 120,
                    align: 'center',
                    slots: {
                        customRender: 'action'
                    }
                }
            ],
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false
        };
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('share.requesting', 0);

            this.$http.post('/user/shipper/delete/', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('share.select_one_more_then');
            }

            this.$confirm({
                title: this.$t('share.tips'),
                content: this.$t('share.delete_confirm'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('share.requesting', 0);

                    this.$http.post('/user/shipper/delete/', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        }
    }
}
